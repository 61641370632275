export default {
  5: ["AREA", "SUPERFICIE"],
  6: ["REAL ESTATE PROPOSAL", "PROPUESTA ECONÓMICA"],
  11: ["Bedrooms", "Recámaras"],
  14: ["Balcony", "Terraza"],
  36: ["Final Proposal", "Precio final"],
  42: ["Final Proposal", "Precio final"],
  81: ["PROPERTY DETAILS", "DETALLE DE UNIDAD"],
  86: ["TOTAL AREA", "SUPERFICIE TOTAL"],
  90: [
    "Quotation Validity:\nUp to 7 days from the date of issue.",
    "Vigencia de la cotización:\nHasta 7 días desde la fecha de emisión"
  ],
  104: [
    "* The exchange rate that will be applied will be\nthat of the day of payment",
    "* Aplicación del tipo de cambio correspondiente\nal día de pago."
  ],
  105: ["Quotation in USD (American Dollars)", "Importes en dólares americanos"]
};
