import {
  GetLeadsById,
  GetLeadDetail,
  GetLeadByGroup,
  GetLeadsSearched,
  GetLeadsPagination,
  GetCompleteLeadSurvey,
  SendNextQuestion,
  UpdateLead,
  GetLeadStats,
  GetLeadsFilters,
  GetLeadsWithFilters,
  GetLeadsDuplicates,
  GetLeadsDuplicados
} from "@/api/lead-api.js";
import { UpdateContactLead } from "@/api/contact-lead-api.js";
import { AddLeadComment } from "@/api/lead-comment-api.js";
import {
  SendNotificationComment,
  SendNotificationAssignBroker
} from "@/api/notification-api.js";
// import { SendNotificationNewComment } from "@/api/notification-api.js";
import moment from "moment";
import headers from "./headersTable";
moment.locale("es");

export default {
  namespaced: true,
  modules: {
    headers
  },
  state: {
    leadsKanban: [],
    leadsByGroup: null,
    leadsByGroupLoading: false,
    leadsSearched: null,
    leadsSearchedLoading: false,
    leadsTableFooter: { page: 0, pages: 0, total: 0 },
    leadsPagination: null,
    leadsPaginationLoading: false,
    leadsDuplicates: null,
    leadsDuplicatesLoading: false,
    items: [], // lista de todos los leads
    leadStats: null,
    actualItem: {}, // usado al visualizar un solo lead
    secondaryItem: {},
    conpleteLeadSurvey: null,
    selectedItems: [], // leads seleccionados (por lo general con checkbox en la tabla leads)
    loading: false,
    filterLeadsSearched: false,
    filtersSelectedChanged: false,
    filtersSelected: {},
    filterSomeFilterSelected: false,
    filterShowLeadsDuplicates: false,
    filterShowDiscarded: false,
    filterShowDiscardedTmp: false,
    filterShowDiscardedAndActive: false,
    filterShowDiscardedAndActiveTmp: false,
    filterShowDelayedLeads: false,
    filterShowDelayedLeadsTmp: false,
    filterInitDateCreateAt: "",
    filterEndDateCreateAt: "",
    filterProfiles: "",
    filterBrokers: "",
    filterBrex: "",
    filterZones: [],
    filterText: "",
    filterLeadStatus: "",
    filterHowDidContactUs: [],
    filterWhereDidContactUs: [],
    filterCampaign: "",
    filterConjunto: "",
    filterAnuncio: "",
    filterFormulario: "",
    clearFilters: false,
    filters: null, // lista de todos los filtros, es muy usado en varias partes del sistema, para datos customizados
    loadingFilters: false, // usar para controlar si los filtros ya cargaron o no,
    leadsDuplicados: {}
  },
  getters: {
    // recupera las fases que estan en filters y si no hay devuele un array de fases por
    // defecto
    getPhases(state) {
      if (state.filters) {
        return state.filters.phases;
      }
      return [
        {
          text: "Por Asignar",
          value: "unassigned",
          data: {
            phase: "active",
            tracking_phase: "unassigned",
            operation_phase: ""
          }
        },
        {
          text: "Asignado",
          value: "assigned",
          data: {
            phase: "active",
            tracking_phase: "assigned",
            operation_phase: ""
          }
        },
        {
          text: "Por Perfilar",
          value: "to-contact",
          data: {
            phase: "active",
            tracking_phase: "to-contact",
            operation_phase: ""
          }
        },
        {
          text: "Búsqueda",
          value: "searching",
          data: {
            phase: "active",
            tracking_phase: "searching",
            operation_phase: ""
          }
        },
        {
          text: "Seguimiento",
          value: "tracking",
          data: {
            phase: "active",
            tracking_phase: "tracking",
            operation_phase: ""
          }
        },
        {
          text: "Recorrido",
          value: "scheduled-tour",
          data: {
            phase: "active",
            tracking_phase: "scheduled-tour",
            operation_phase: ""
          }
        },
        {
          text: "Seguimiento post-recorrido",
          value: "finished-tour",
          data: {
            phase: "active",
            tracking_phase: "finished-tour",
            operation_phase: ""
          }
        },
        {
          text: "Ofertando",
          value: "offer",
          data: {
            phase: "active",
            tracking_phase: "offer",
            operation_phase: ""
          }
        },
        {
          text: "Apartado",
          value: "downpayment",
          data: {
            phase: "active",
            tracking_phase: "downpayment",
            operation_phase: ""
          }
        },
        {
          text: "Promesa",
          value: "contract",
          data: {
            phase: "in-operation",
            tracking_phase: "",
            operation_phase: "contract"
          }
        },
        {
          text: "Cierre",
          value: "closing-trade",
          data: {
            phase: "in-operation",
            tracking_phase: "",
            operation_phase: "closing-trade"
          }
        },
        {
          text: "Clientes - Post-Venta",
          value: "finished",
          data: {
            phase: "finished",
            tracking_phase: "",
            operation_phase: ""
          }
        },
        {
          text: "Descartados",
          value: "discarded",
          data: {
            phase: "discarded",
            tracking_phase: "",
            operation_phase: ""
          }
        }
      ];
    },
    getSources(state) {
      if (state.filters?.sources) {
        return state.filters.sources.map(source => {
          return source.source;
        });
      }
      return [];
    },
    getMediums(state) {
      if (state.filters?.mediums) {
        return state.filters.mediums.map(medium => {
          return medium.medium;
        });
      }
      return [];
    },
    getZones(state) {
      if (state.filters?.zones) {
        return state.filters.zones;
      }
      return [];
    },
    // obtiene todos los leads con los filtros que estén activados
    filteredLeads(state) {
      let filteredLeads = state.leadsPagination ?? [];
      state.filtersSelectedChanged = false;
      let filters = {};

      if (state.filterShowLeadsDuplicates) {
        state.filtersSelectedChanged = true;
      }

      if (state.filterBrokers) {
        //Se añade el filtro por asesor
        state.filtersSelectedChanged = true;
        filters.contactId =
          state.filterBrokers === "all" ? undefined : state.filterBrokers;
      }

      if (state.filterBrex) {
        //Se añade el filtro por asesor
        state.filtersSelectedChanged = true;
        filters.contact_external_broker_id = state.filterBrex;
      }

      if (state.filterLeadStatus) {
        //Se añade el filtro por fase
        state.filtersSelectedChanged = true;
        switch (state.filterLeadStatus.phase) {
          case "active":
            filters.type = state.filterLeadStatus.tracking_phase;
            break;
          case "in-operation":
            filters.type = state.filterLeadStatus.operation_phase;
            break;
          default:
            filters.type = state.filterLeadStatus.phase;
            break;
        }
      }

      if (
        state.filterHowDidContactUs &&
        state.filterHowDidContactUs.length > 0
      ) {
        //Se añade el filtro por source
        state.filtersSelectedChanged = true;
        if (filters.traffic) {
          filters.traffic.source = state.filterHowDidContactUs.map(a => {
            return a._id;
          });
        } else {
          filters.traffic = {
            source: state.filterHowDidContactUs.map(a => {
              return a._id;
            })
          };
        }
      }
      if (
        state.filterWhereDidContactUs &&
        state.filterWhereDidContactUs.length > 0
      ) {
        //Se añade el filtro por source
        state.filtersSelectedChanged = true;
        if (filters.traffic) {
          filters.traffic.medium = state.filterWhereDidContactUs.map(a => {
            return a._id;
          });
        } else {
          filters.traffic = {
            medium: state.filterWhereDidContactUs.map(a => {
              return a._id;
            })
          };
        }
      }

      if (state.filterZones.length > 0) {
        //Se añade el filtro por zones
        state.filtersSelectedChanged = true;
        filters.zones = state.filterZones;
      }

      if (state.filterInitDateCreateAt) {
        //Se añade el filtro de fecha de inicio
        state.filtersSelectedChanged = true;
        if (filters.date) {
          filters.date.start = state.filterInitDateCreateAt;
        } else {
          filters.date = {
            start: state.filterInitDateCreateAt
          };
        }
      }

      if (state.filterEndDateCreateAt) {
        //Se añade el filtro de fecha de final
        state.filtersSelectedChanged = true;
        if (filters.date) {
          filters.date.end = state.filterEndDateCreateAt;
        } else {
          filters.date = {
            end: state.filterEndDateCreateAt
          };
        }
      }

      if (state.filterProfiles) {
        //Se añade el filtro de perfil: a,b,c,d
        state.filtersSelectedChanged = true;
        filters.profile = state.filterProfiles.toUpperCase();
      }

      if (state.filterCampaign) {
        //Se añade el filtro de mkt campaña
        state.filtersSelectedChanged = true;
        if (filters.mkt) {
          filters.mkt.campaign = state.filterCampaign;
        } else {
          filters.mkt = {
            campaign: state.filterCampaign
          };
        }
      }
      if (state.filterConjunto) {
        //Se añade el filtro de mkt conjunto
        state.filtersSelectedChanged = true;
        if (filters.mkt) {
          filters.mkt.adSet = state.filterConjunto;
        } else {
          filters.mkt = {
            adSet: state.filterConjunto
          };
        }
      }
      if (state.filterAnuncio) {
        //Se añade el filtro de mkt anuncio
        state.filtersSelectedChanged = true;
        if (filters.mkt) {
          filters.mkt.ad = state.filterAnuncio;
        } else {
          filters.mkt = {
            ad: state.filterAnuncio
          };
        }
      }
      if (state.filterFormulario) {
        //Se añade el filtro de mkt formulario
        state.filtersSelectedChanged = true;
        if (filters.mkt) {
          filters.mkt.form = state.filterFormulario;
        } else {
          filters.mkt = {
            form: state.filterFormulario
          };
        }
      }

      if (state.filterShowDelayedLeads) {
        //Se muestran solo los vencido, pero esta pendiente
        state.filterShowDelayedLeadsTmp = true;
        state.filtersSelectedChanged = true;
        filters.leadPostponed = true;
      }

      if (!state.filterShowDelayedLeads && state.filterShowDelayedLeadsTmp) {
        state.filterShowDelayedLeadsTmp = false;
        state.filtersSelectedChanged = true;
      }

      if (state.filterShowDiscardedAndActive) {
        //Se añade el filtro de mostrar todos
        state.filtersSelectedChanged = true;
        state.filterShowDiscardedAndActiveTmp = true;
        if (state.filterLeadStatus) {
          state.filterLeadStatus = "";
          delete filters["type"];
          filters.type = "phase";
          filters.get = ["active", "in-operation", "finished", "discarded"];
        } else {
          filters.type = "phase";
          filters.get = ["active", "in-operation", "finished", "discarded"];
        }
      }
      if (
        !state.filterShowDiscardedAndActive &&
        state.filterShowDiscardedAndActiveTmp
      ) {
        state.filtersSelectedChanged = true;
        state.filterShowDiscardedAndActive = false;
      }

      if (state.filterShowDiscarded) {
        //Se añade el filtro de mostrar solo descartados
        state.filtersSelectedChanged = true;
        state.filterShowDiscardedTmp = true;
        if (state.filterLeadStatus) {
          state.filterLeadStatus = "";
        }
        filters.type = "discarded";
      }
      if (!state.filterShowDiscarded && state.filterShowDiscardedTmp) {
        state.filtersSelectedChanged = true;
        state.filterShowDiscardedTmp = false;
      }
      state.filtersSelected = filters;
      return filteredLeads;
    }
  },
  mutations: {
    setFiltersSelectedChanged(state, value) {
      state.filtersSelectedChanged = value;
    },
    setFiltersSelected(state, values) {
      state.filtersSelected = values;
    },
    setFilteredLeadsSearched(state, value) {
      state.filterLeadsSearched = value;
    },
    setLeadsKanban(state, data) {
      if (data !== undefined && data !== null) {
        if (data.update) {
          for (let i = 0; i < state.leadsKanban.length; i++) {
            if (i === data.index) {
              state.leadsKanban.splice(i, 1, data);
            }
          }
        } else {
          state.leadsKanban.push(data);
        }
      } else {
        state.leadsKanban = [];
      }
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    setLoadingFilters(state, value) {
      state.loadingFilters = value;
    },
    setLeadsByGroup(states, items) {
      states.leadsByGroup = items;
    },
    setLeadsByGroupLoading(state, value) {
      state.leadsByGroupLoading = value;
    },
    setLeadsSearched(states, items) {
      states.leadsSearched = items;
    },
    setLeadsSearchedLoading(state, value) {
      state.leadsSearchedLoading = value;
    },
    setLeadsTableFooter(states, data) {
      states.leadsTableFooter = data;
    },
    setLeadsPaginationClear(states) {
      states.leadsPagination = [];
    },
    setLeadsPaginationReset(states) {
      let final = states.leadsPagination;
      if (final.length > 25) {
        final.slice(0, 25);
      }
      // states.leadsPagination = final;
    },
    setLeadsPaginationReduce(states, data) {
      let final = data.items;
      for (let i = 0; i < data.reduce.length; i++) {
        final.pop();
      }
      states.leadsPagination = final;
    },
    setLeadsPagination(states, items) {
      if (items === null) {
        states.leadsPagination = [];
      } else if (states.leadsPagination) {
        states.leadsPagination.push.apply(states.leadsPagination, items);
      } else {
        states.leadsPagination = items;
      }
    },
    setLeadsPaginationLoading(state, value) {
      state.leadsPaginationLoading = value;
    },
    setLeadsDuplicates(states, items) {
      states.leadsDuplicates = items;
    },
    setLeadsDuplicatesLoading(state, value) {
      state.leadsDuplicatesLoading = value;
    },
    // actualiza la información de los leads en Vuex con el valor que se esta pasando
    setLeadStats(state, stats) {
      state.leadStats = stats;
    },
    setActualLead(state, lead) {
      state.actualItem = lead;
    },
    setSecondaryLead(state, data) {
      state.secondaryItem = data;
    },
    setCompleteLeadSurvey(state, completeSurvey) {
      state.completeLeadSurvey = completeSurvey;
    },
    resetActualLead(state) {
      state.actualItem = {};
    },
    setClearFilters(state, value) {
      state.clearFilters = value;
    },
    setfilterSomeFilterSelected(state, value) {
      state.filterSomeFilterSelected = value;
    },
    setfilterShowLeadsDuplicates(state, value) {
      state.filterSomeFilterSelected = value;
      state.filterShowLeadsDuplicates = value;
    },
    setFilterShowDiscarded(state, value) {
      state.filterLeadStatus = value ? "" : state.filterLeadStatus;
      state.filterSomeFilterSelected = value;
      state.filterShowDiscardedAndActive = false;
      state.filterShowDiscarded = value;
    },
    setFilterShowDiscardedAndActive(state, value) {
      state.filterLeadStatus = value ? "" : state.filterLeadStatus;
      state.filterSomeFilterSelected = value;
      state.filterShowDiscarded = false;
      state.filterShowDiscardedAndActive = value;
    },
    setFilterShowDelayed(state, value) {
      state.filterSomeFilterSelected = value;
      state.filterShowDelayedLeads = value;
    },
    setFilterInitDateCreateAt(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterInitDateCreateAt = value;
    },
    setFilterEndDateCreateAt(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterEndDateCreateAt = value;
    },
    setFilterProfiles(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterProfiles = value;
    },
    setFilterBrokers(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterBrokers = value;
    },
    setFilterBrex(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterBrex = value;
    },
    setFilterLeadStatus(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterLeadStatus = value;
    },
    setFilterHowDidContactUs(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterHowDidContactUs = value;
    },
    setFilterWhereDidContactUs(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterWhereDidContactUs = value;
    },
    setFilterText(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterText = value;
    },
    setFilterZones(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterZones = value;
    },
    setFilterCampaign(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterCampaign = value;
    },
    setFilterConjunto(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterConjunto = value;
    },
    setFilterAnuncio(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterAnuncio = value;
    },
    setFilterFormulario(state, value) {
      state.filterSomeFilterSelected = value ? true : false;
      state.filterFormulario = value;
    },

    // actualiza uno o más campos del lead actual
    updateActualLead(state, leadData) {
      const view = () => {
        // Esto se puso porque en ciertas partes del codigo usan diferente termino
        return leadData.page === "list" || leadData.page === "table";
      };
      // Busca su index en la lista de leads
      let indexPagination = -1;
      let indexSearched = -1;
      if (leadData.hasOwnProperty("leadId") && view()) {
        indexPagination = state.leadsPagination?.findIndex(
          e => e._id === leadData.leadId
        );
      }
      if (
        state.filterLeadsSearched &&
        leadData.hasOwnProperty("leadId" && view())
      ) {
        indexSearched = state.leadsSearched.findIndex(
          e => e._id === leadData.leadId
        );
      }
      // actualizar cada campo que se esta pasandoss
      for (const key in leadData) {
        if (
          leadData.page === "kanban" ||
          leadData.page === "leadDetailkanban"
        ) {
          let leadValue = "";
          switch (state.actualItem.phase) {
            case "active":
              leadValue = state.actualItem.tracking_phase;
              break;
            case "in-operation":
              leadValue = state.actualItem.operation_phase;
              break;
            default:
              leadValue = state.actualItem.phase;
              break;
          }

          let removeFromPhase = false;
          let toValuePhase = "";
          if (leadData.hasOwnProperty(key)) {
            if (
              key === "name" ||
              key === "phone" ||
              key === "email" ||
              key === "location"
            ) {
              if (!state.actualItem.contact_lead[key]) {
                state.actualItem.contact_lead[key] = "";
              }
              state.actualItem.contact_lead[key] = leadData[key];
            } else if (key === "contact") {
              if (leadData.contact.hasOwnProperty("source")) {
                let medium = state.actualItem[key].medium;
                state.actualItem[key].source = leadData.contact.source;
                state.actualItem[key].medium = medium;
              } else {
                state.actualItem[key].medium = leadData.contact.medium;
              }
            } else if (key === "value_phase") {
              removeFromPhase = true;
              toValuePhase = leadData.value_phase;
              state.actualItem.phase = leadData.phase;
              state.actualItem.tracking_phase = leadData.tracking_phase;
              state.actualItem.operation_phase = leadData.operation_phase;
            } else if (key === "postponed") {
              state.actualItem.postponed = leadData[key];
            } else if (key === "profile_percentage") {
              state.actualItem.profile_percentage = leadData[key];
            } else {
              state.actualItem[key] = leadData[key];
            }
          }
          if (removeFromPhase) {
            //Removemos el lead del array al que actualmente pertenece
            // ---------------------------------------------- //
            for (let i = 0; i < state.leadsKanban.length; i++) {
              let leads = state.leadsKanban[i].leads.filter(l => {
                return l._id !== state.actualItem._id;
              });
              state.leadsKanban[i].leads = [];
              state.leadsKanban[i].leads = leads;
            }

            //Lo añadimos al array al que debe pertenecer //
            // ----------------------------------------- //

            let indexTo = state.leadsKanban.findIndex(k => {
              return k.value === toValuePhase;
            });
            state.leadsKanban[indexTo].leads.push(state.actualItem);
          } else {
            let indexItem = state.leadsKanban.findIndex(k => {
              return k.value === leadValue;
            });
            if (indexItem >= 0) {
              let leads = state.leadsKanban[indexItem].leads;
              for (let i = 0; i < leads.length; i++) {
                if (leads[i]._id === state.actualItem._id) {
                  leads.splice(i, 1, state.actualItem);
                  break;
                }
              }
              leads.sort((a, b) => {
                let dateA =
                  a.postponed && a.postponed.date_at
                    ? new Date(a.postponed.date_at)
                    : new Date();
                let dateB =
                  b.postponed && b.postponed.date_at
                    ? new Date(b.postponed.date_at)
                    : new Date();

                return dateA - dateB;
              });
              state.leadsKanban[indexItem].leads = leads;
            }
          }
        } else {
          if (leadData.hasOwnProperty(key)) {
            if (
              key === "name" ||
              key === "phone" ||
              key === "email" ||
              key === "location"
            ) {
              if (!state.actualItem.contact_lead[key]) {
                state.actualItem.contact_lead[key] = "";
              }
              state.actualItem.contact_lead[key] = leadData[key];
              if (indexPagination >= 0) {
                state.leadsPagination[indexPagination].contact_lead[key] =
                  leadData[key];
              }
            } else if (key === "contact") {
              if (leadData.contact.hasOwnProperty("source")) {
                let medium = state.actualItem[key].medium;
                state.actualItem[key].source = leadData.contact.source;
                state.actualItem[key].medium = medium;
                // actualiza el state de la tabla
                if (indexPagination >= 0) {
                  state.leadsPagination[indexPagination][key].source =
                    leadData[key].source;
                }
              } else {
                state.actualItem[key].medium = leadData.contact.medium;
                if (indexPagination >= 0) {
                  state.leadsPagination[indexPagination][key].medium =
                    leadData[key].medium;
                }
              }
            } else if (key === "profile_percentage") {
              state.actualItem.profile_percentage = leadData[key];
            } else if (key === "internal_broker" || key === "internal_admin") {
              if (state.filterLeadsSearched) {
                let index = state.leadsSearched.findIndex(
                  e => e._id === leadData.leadId
                );
                if (index >= 0) {
                  state.leadsSearched[index].internal_broker = leadData[key];
                }
              } else {
                let index = state.leadsPagination.findIndex(
                  e => e._id === leadData.leadId
                );
                if (index >= 0) {
                  if (leadData[key].role === "broker") {
                    if (state.leadsPagination[index].internal_broker) {
                      state.leadsPagination[index].internal_broker =
                        leadData[key];
                    } else {
                      state.leadsPagination[index].internal_admin =
                        leadData[key];
                    }
                  }
                  if (leadData[key].role === "admin") {
                    if (state.leadsPagination[index].internal_admin) {
                      state.leadsPagination[index].internal_admin =
                        leadData[key];
                    } else {
                      state.leadsPagination[index].internal_broker =
                        leadData[key];
                    }
                  }
                }
              }
            } else {
              state.actualItem[key] = leadData[key];
              if (state.filterLeadsSearched) {
                if (indexSearched >= 0) {
                  state.leadsSearched[indexSearched][key] = leadData[key];
                }
              } else {
                if (indexPagination >= 0) {
                  state.leadsPagination[indexPagination][key] = leadData[key];
                }
              }
            }
          }
        }
      }
    },
    setUpdateLeadByIdField(state, data) {
      switch (data.field) {
        case "profile":
          configProfile(state, data);
          break;
        case "profile_percentage":
          for (let i = 0; i < state.items.length; i++) {
            if (state.items[i]._id === data.id) {
              state.items[i].profile_percentage = data.data.profile_percentage;
              break;
            }
          }
          for (let i = 0; i < state.leadsPagination.length; i++) {
            if (state.leadsPagination[i]._id === data.id) {
              state.leadsPagination[i].profile_percentage =
                data.data.profile_percentage;
              break;
            }
          }
          for (let i = 0; i < state.leadsSearched.length; i++) {
            if (state.leadsSearched[i]._id === data.id) {
              state.leadsSearched[i].profile = data.data.profile;
              break;
            }
          }
          break;

        default:
          break;
      }
    },
    setSelectedItems(state, leads) {
      state.selectedItems = leads;
    },
    setLoading(state, loading) {
      // establece que se esta cargando información
      // ej si se registra un nuevo lead, manda un loading a la tabla de leads
      state.loading = loading;
    },
    setContactLeads() {},
    /**
     * Funcion que actualiza una propiedad del lead, unicamente actualiza las propidades del
     * primer nivel, es decir puede actualizar lead.postponed pero no lead.postponed.date_at
     * recibe 1 objeto clave valor que debe provenir del action "updatePropertiesLead"
     *
     * * En caso de que no se actualize algun dato, revisar en que lisa se encuentra y añadirlo
     * * Revisar el nombre de los atributos que se quieren actualizar
     *
     * @param {object} obj Es el objeto a actualizar
     * @example {1,0, {postponed: {isPostponed: true, date_at: "2023-08-09T21:30:49.000Z"}}}
     */
    setPropertyLead(state, { obj, _id }) {
      try {
        const keys = Object.keys(obj);
        let indexPagination = -1;
        let indexSearched = -1;
        // search
        if (state.leadsPagination)
          indexPagination = state.leadsPagination.findIndex(e => e._id === _id);
        if (state.filterLeadsSearched)
          indexSearched = state.leadsSearched.findIndex(e => e._id === _id);
        // Valida si se encuentra en cada lista y actualiza todos los valores que tenga
        keys.forEach(key => {
          if (indexSearched >= 0)
            state.leadsSearched[indexSearched][key] = obj[key];
          if (indexPagination >= 0)
            state.leadsPagination[indexPagination][key] = obj[key];
          if (state.actualItem._id === _id) state.actualItem[key] = obj[key];
        });
      } catch (error) {
        return;
      }
    },
    setLeadsDuplicados(state, leadsDuplicadosResponse) {
      state.leadsDuplicados = leadsDuplicadosResponse;
    },
    updateLeadInList(state, lead) {
      let index = state.leadsPagination.findIndex(e => e._id === lead._id);

      if (index >= 0) {
        state.leadsPagination[index] = {
          ...state.leadsPagination[index],
          ...lead
        };
      }
    },
    updateContactLeadInList(state, lead) {
      let index = state.leadsPagination.findIndex(e => e._id === lead._id);

      if (index >= 0) {
        state.leadsPagination[index].contact = {
          ...state.leadsPagination[index].contact,
          ...lead.contact
        };
      }
    }
  },
  actions: {
    /**
     *
     * @param {} param0
     * @param {*} data
     * @returns
     */
    updatePropertiesLead({ commit }, { properties, _id }) {
      const obj = { ...properties };
      commit("setPropertyLead", { obj, _id });
    },
    fetchLeadsPagination({ commit }, data) {
      try {
        if (!data.footer && data.page === 0) {
          commit("setLeadsPagination", null);
          commit("setLeadsPaginationLoading", true);
          return new Promise(resolve => {
            let filters =
              Object.entries(data.filter).length === 0
                ? {
                    type: "phase",
                    get: ["active", "in-operation", "finished"]
                  }
                : data.filter;
            GetLeadsPagination(data.page, data.limit, filters, "recents")
              .then(res => {
                commit("setLeadsPagination", res.Lead);
                commit("setLeadsTableFooter", res.Options);
                commit("setLeadsPaginationLoading", false);
                resolve(res.Lead);
              })
              .catch(err => {
                commit("setLeadsPaginationLoading", false);
                throw new Error(err);
              });
          });
        } else {
          if (data.page !== 0) {
            // Como no es la pagina 0 entra aqui
            commit("setLeadsPaginationLoading", true);
            return new Promise(resolve => {
              let filters =
                Object.entries(data.filter).length === 0
                  ? {
                      type: "phase",
                      get: ["active", "in-operation", "finished"]
                    }
                  : data.filter;
              GetLeadsPagination(data.page, data.limit, filters, "recents")
                .then(async res => {
                  // Se llama al commit para insertar los nuevos elementos
                  commit("setLeadsPagination", res.Lead);
                  data.footer.more = false;
                  commit("setLeadsTableFooter", data.footer);
                  commit("setLeadsPaginationLoading", false);
                  resolve(res.Lead);
                })
                .catch(err => {
                  data.footer.more = false;
                  commit("setLeadsTableFooter", data.footer);
                  commit("setLeadsPaginationLoading", false);
                  throw new Error(err);
                });
            });
          }
        }
      } catch (err) {
        commit("setLeadsPaginationLoading", false);
        return Promise.reject(err);
      }
    },
    fetchMoreLeadsKanbanByPhase({ commit }, data) {
      let final = data;
      final.more = true;
      final.footer.page = final.footer.page + 1;
      GetLeadsPagination(final.footer.page, 25, data.filter, "postpone")
        .then(value => {
          final.leads.push(...value.Lead);
          final.more = false;
          commit("setLeadsKanban", final);
        })
        .catch(() => {
          final.more = false;
          final.error = true;
          commit("setLeadsKanban", final);
        });
    },
    fetchGetLeadsKanbanByPhase({ commit }, data) {
      try {
        const currentFilter =
          data.filter.type === "phase" ? undefined : data.filter.type;
        if (data.filter && data.filter.type) {
          delete data.filter["type"];
        }
        let leadFilter = [];
        for (let i = 0; i < data.phases.length; i++) {
          leadFilter.push({
            type: data.phases[i].value
          });
        }
        if (data.filter) {
          for (let i = 0; i < leadFilter.length; i++) {
            Object.assign(leadFilter[i], data.filter);
          }
        }

        for (let i = 0; i < data.phases.length; i++) {
          commit("setLeadsKanban", {
            index: i,
            update: false,
            more: false,
            data: data.phases[i].data,
            text: data.phases[i].text,
            value: data.phases[i].value,
            filter: leadFilter[i],
            loading: true,
            leads: [],
            error: false,
            footer: null
          });
        }

        for (let i = 0; i < data.phases.length; i++) {
          if (currentFilter) {
            if (currentFilter === data.phases[i].value) {
              GetLeadsPagination(data.page, 25, leadFilter[i], "postpone")
                .then(value => {
                  commit("setLeadsKanban", {
                    index: i,
                    update: true,
                    more: false,
                    data: data.phases[i].data,
                    text: data.phases[i].text,
                    value: data.phases[i].value,
                    filter: leadFilter[i],
                    loading: false,
                    leads: value.Lead,
                    error: false,
                    footer: value.Options ? value.Options : null
                  });
                })
                .catch(() => {
                  commit("setLeadsKanban", {
                    index: i,
                    update: true,
                    more: false,
                    data: data.phases[i].data,
                    text: data.phases[i].text,
                    value: data.phases[i].value,
                    filter: leadFilter[i],
                    loading: false,
                    leads: [],
                    error: true,
                    footer: null
                  });
                });
            } else {
              commit("setLeadsKanban", {
                index: i,
                update: true,
                more: false,
                data: data.phases[i].data,
                text: data.phases[i].text,
                value: data.phases[i].value,
                filter: leadFilter[i],
                loading: false,
                leads: [],
                error: false,
                footer: null
              });
            }
          } else {
            GetLeadsPagination(data.page, 25, leadFilter[i], "postpone")
              .then(value => {
                commit("setLeadsKanban", {
                  index: i,
                  update: true,
                  more: false,
                  data: data.phases[i].data,
                  text: data.phases[i].text,
                  value: data.phases[i].value,
                  filter: leadFilter[i],
                  loading: false,
                  leads: value.Lead,
                  error: false,
                  footer: value.Options ? value.Options : null
                });
              })
              .catch(() => {
                commit("setLeadsKanban", {
                  index: i,
                  update: true,
                  more: false,
                  data: data.phases[i].data,
                  text: data.phases[i].text,
                  value: data.phases[i].value,
                  filter: leadFilter[i],
                  loading: false,
                  leads: [],
                  error: true,
                  footer: null
                });
              });
          }
        }
      } catch (error) {
        commit("setLeadsKanban", null);
      }
    },
    fetchGetLeadsFilters({ commit }) {
      try {
        commit("setLoadingFilters", true);
        return new Promise(resolve => {
          GetLeadsFilters()
            .then(res => {
              commit("setFilters", res.filters);
              const sources = res.filters.sources.map(({ source }) => source);
              const mediums = res.filters.mediums.map(({ medium }) => medium);
              commit("traffics/setSources", sources, { root: true });
              commit("traffics/setMediums", mediums, { root: true });
              commit("setLoadingFilters", false);
              resolve(res.filters);
            })
            .catch(err => {
              throw new Error(err);
            })
            .finally(() => {
              commit("setLoadingFilters", false);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    fetchGetLeadsDuplicates({ commit }, data) {
      try {
        commit("setLeadsDuplicatesLoading", true);
        return new Promise(resolve => {
          GetLeadsDuplicates(data.field, data.page)
            .then(res => {
              commit("setLeadsDuplicates", res.Lead);
              commit("setLeadsDuplicatesLoading", false);
              resolve({ Lead: res.Lead, Options: res.Options });
            })
            .catch(err => {
              commit("setLeadsDuplicatesLoading", false);
              throw new Error(err);
            });
        });
      } catch (err) {
        commit("setLeadsDuplicatesLoading", false);
        return Promise.reject(err);
      }
    },
    fetchLeadsSearched({ commit }, search) {
      commit("setLeadsSearchedLoading", true);
      try {
        return new Promise(resolve => {
          GetLeadsSearched(search)
            .then(res => {
              commit("setLeadsSearched", res.Lead);
              commit("setLeadsSearchedLoading", false);
              resolve(res.Lead);
            })
            .catch(err => {
              commit("setLeadsSearchedLoading", false);
              throw new Error(err);
            });
        });
      } catch (err) {
        commit("setLeadsSearchedLoading", false);
        return Promise.reject(err);
      }
    },
    fetchLeadsByGroup({ commit }) {
      commit("setLeadsByGroupLoading", true);
      try {
        return new Promise(resolve => {
          GetLeadByGroup()
            .then(res => {
              commit("setLeadsByGroup", res.Lead);
              commit("setLeadsByGroupLoading", false);
              resolve(res.Lead);
            })
            .catch(err => {
              commit("setLeadsByGroupLoading", false);
              throw new Error(err);
            });
        });
      } catch (err) {
        commit("setLeadsByGroupLoading", false);
        return Promise.reject(err);
      }
    },
    fetchLeadsToExport({ commit }, data) {
      try {
        return new Promise(resolve => {
          GetLeadsWithFilters(data.filter)
            .then(res => {
              resolve(res.Lead);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        commit("setLoading", false);
        return Promise.reject(err);
      }
    },
    fetchLeadsBydContactId({ commit }, item) {
      try {
        return new Promise(resolve => {
          // consultar API C28
          // actualizar valor VUEX
          commit("setContactLeads");
          GetLeadsById(item)
            .then(res => {
              resolve(res);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    // obtiene el lead del API C28 y lo asigna como item actual
    fetchActualLead({ commit }, leadId) {
      try {
        return new Promise(resolve => {
          // consultar API C28
          // actualizar valor VUEX
          GetLeadDetail(leadId)
            .then(res => {
              commit("setActualLead", res.Lead);
              resolve(res.Lead);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    fetchLeadStats({ commit }) {
      try {
        return new Promise(resolve => {
          GetLeadStats()
            .then(res => {
              commit("setLeadStats", res.response.data);
              resolve();
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    fetchCompleteLeadSurvey({ commit }, leadId) {
      try {
        return new Promise(resolve => {
          GetCompleteLeadSurvey(leadId)
            .then(res => {
              commit("setCompleteLeadSurvey", res);
              resolve(res.data);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    nextQuestion({ commit }, body) {
      try {
        return new Promise(resolve => {
          SendNextQuestion(body.leadId, body.questionId, body.answer)
            .then(res => {
              commit("setCompleteLeadSurvey", res);
              resolve(res.data);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async fetchUpdatelLead({ commit }, updateData) {
      try {
        let promises = [];
        if (!updateData.lead)
          throw new Error("No se especificó parámetro leadId");
        if (!updateData.lead)
          throw new Error("No se especificó parámetro lead");

        let leadId = updateData.leadId;
        let contactLeadId = updateData.contactLeadId;
        let dataContactLead = {};
        let dataLead = {};
        // evaluar que datos se van a editar
        if (updateData.lead) {
          // datos del contact_lead
          if (
            updateData.lead.name ||
            updateData.lead.contact_lead_email != null ||
            updateData.lead.contact_lead_phone != null ||
            updateData.lead.contact_lead_location != null
          ) {
            if (updateData.lead.name)
              dataContactLead.name = updateData.lead.name;
            if (updateData.lead.contact_lead_email != null)
              dataContactLead.email = updateData.lead.contact_lead_email;
            if (updateData.lead.contact_lead_phone != null)
              dataContactLead.phone = updateData.lead.contact_lead_phone;
            if (updateData.lead.contact_lead_location != null)
              dataContactLead.location = updateData.lead.contact_lead_location;
            promises.push(
              UpdateContactLead(contactLeadId, dataContactLead, leadId)
            );
          }
          // datos del lead
          if (
            updateData.lead.contactBrokerId ||
            updateData.lead.name ||
            updateData.lead.budget != null ||
            updateData.lead.budget != undefined ||
            updateData.lead.currency ||
            updateData.lead.zones ||
            updateData.lead.profile ||
            updateData.lead.observations_detail ||
            updateData.lead.phase ||
            updateData.lead.contact ||
            updateData.lead.postponed_date ||
            updateData.lead.reviewed ||
            updateData.lead.contacted
          ) {
            // actualizar el nombre en contact_lead y en lead
            if (updateData.lead.name)
              dataLead.contact_lead_name = updateData.lead.name;
            if (updateData.lead.budget >= 0)
              dataLead.budget = updateData.lead.budget;
            if (updateData.lead.currency)
              dataLead.currency = updateData.lead.currency;
            if (updateData.lead.zones) dataLead.zones = updateData.lead.zones;
            if (updateData.lead.profile) {
              dataLead.profile = updateData.lead.profile;
              commit("setUpdateLeadByIdField", {
                id: leadId,
                field: "profile",
                data: {
                  profile: updateData.lead.profile
                }
              });
            }
            if (
              updateData.lead.observations_detail !== undefined &&
              updateData.lead.observations_detail !== null
            ) {
              dataLead.observations_detail =
                updateData.lead.observations_detail;
            }

            if (updateData.lead.phase) {
              dataLead.phase = updateData.lead.phase;
            }
            if (!updateData.lead.operation_phase) {
              if (updateData.lead.tracking_phase) {
                dataLead.tracking_phase = updateData.lead.tracking_phase;
                dataLead.operation_phase = "";
                dataLead.phase = "active";
              }
            } else {
              dataLead.tracking_phase = "";
              dataLead.operation_phase = updateData.lead.operation_phase;
              dataLead.phase = "in-operation";
            }
            if (updateData.lead.discard_observations) {
              dataLead.discard_observations =
                updateData.lead.discard_observations;
            }
            if (updateData.lead.contactBrokerId) {
              dataLead.contactBrokerId = updateData.lead.contactBrokerId;
              if (updateData.lead.assinedPhase === "unassigned") {
                dataLead.tracking_phase = "assigned";
              }
            }

            if (updateData.lead.postponed_date) {
              dataLead.postponed = {
                is_postponed: true,
                date_at: updateData.lead.postponed_date
              };
            }

            if (updateData.lead.contact) {
              dataLead.contact = updateData.lead.contact;
            }
            if (updateData.lead.reviewed) {
              dataLead.reviewed = updateData.lead.reviewed;
            }
            if (updateData.lead.contacted)
              dataLead.contacted = updateData.lead.contacted;

            promises.push(UpdateLead(leadId, dataLead));

            if (
              updateData.lead.contactBrokerId &&
              updateData.lead.nameAssignBroker
            ) {
              let dataAssignBroker = {
                zones: updateData.zones,
                contactBrokerId: updateData.lead.contactBrokerId,
                nameAssignBroker: updateData.lead.nameAssignBroker
              };
              promises.push(
                SendNotificationAssignBroker(leadId, dataAssignBroker)
              );
            }
          }
        }

        if (promises.length === 0) return Promise.reject("Nada por editar");
        return Promise.all(promises)
          .then(values => {
            commit("setLoading", true);
            return Promise.resolve(values);
          })
          .catch(err => {
            throw new Error(err);
          });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    fetchAddComment(state, commentData) {
      try {
        if (!commentData.leadId)
          throw new Error("No se especificó parámetro leadId");
        if (!commentData.commentText && !commentData.comment_img_src)
          throw new Error("No se especificó parámetro commentText");
        if (!commentData.userRole)
          throw new Error("No se especificó parámetro userRole");
        if (!commentData.contactId)
          throw new Error("No se especificó parámetro contactId");

        return new Promise(resolve => {
          // consultar API C28
          // actualizar valor VUEX
          AddLeadComment(
            commentData.leadId,
            commentData.commentText,
            commentData.comment_img_src,
            commentData.userRole,
            commentData.contactId
          )
            .then(res => {
              resolve(res.Lead);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    fetchNotificationComment(state, commentData) {
      try {
        if (!commentData.leadId)
          throw new Error("No se especificó parámetro leadId");
        if (!commentData.message)
          throw new Error("No se especificó parámetro message");

        return new Promise(resolve => {
          // consultar API C28
          let body = {
            role: commentData.role,
            lead_id: commentData.leadId,
            message: commentData.message,
            headings: commentData.headings,
            large_icon: commentData.largeIcon,
            notificationType: "lead-comment",
            zones: commentData.zones
          };
          if (commentData.role !== "broker") {
            body.contact_id = commentData.contactId;
          }
          SendNotificationComment(body)
            .then(res => {
              resolve(res.NotifiedUsers);
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    fetchLeadsTableFooter({ commit }) {
      commit("setLeadsTableFooter", null);
    },
    fetchLeadDuplicados({ commit }, lead_id) {
      try {
        return new Promise(resolve => {
          GetLeadsDuplicados(lead_id)
            .then(res => {
              commit("setLeadsDuplicados", res.response.data);
              resolve();
            })
            .catch(err => {
              throw new Error(err);
            });
        });
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async updateLeadV2({ commit }, data) {
      const { leadId, dataLead } = data;
      try {
        const response = await UpdateLead(leadId, dataLead);

        if (!response.Lead)
          throw new Error("Error al actualizar el lead, intente de nuevo");
        if (Object.keys(dataLead).includes("contact"))
          commit("updateContactLeadInList", response.Lead);
        else commit("updateLeadInList", response.Lead);
        return response;
      } catch (error) {
        return { Message: error.message };
      }
    }
  }
};
//Se agregó la configuración de profile en una función
function configProfile(state, data) {
  if (state.items) {
    for (let i = 0; i < state.items.length; i++) {
      if (state.items[i]._id === data.id) {
        state.items[i].profile = data.data.profile;
        break;
      }
    }
  }

  if (state.leadsPagination) {
    for (let i = 0; i < state.leadsPagination.length; i++) {
      if (state.leadsPagination[i]._id === data.id) {
        state.leadsPagination[i].profile = data.data.profile;
        break;
      }
    }
  }

  if (state.leadsSearched) {
    for (let i = 0; i < state.leadsSearched.length; i++) {
      if (state.leadsSearched[i]._id === data.id) {
        state.leadsSearched[i].profile = data.data.profile;
        break;
      }
    }
  }
}
