<template>
  <div>
    <v-row no-gutters>
      <v-col :md="sendingMessage ? '11' : '12'">
        <v-text-field
          :disabled="sendingMessage || role_comments === 'marketing'"
          dense
          outlined
          label="Nuevo comentario"
          :append-outer-icon="sendingMessage ? '' : 'mdi-send'"
          v-model="newComment"
          @click:append-outer="handleAddComment"
          @keyup.enter="handleAddComment"
        >
          <template v-slot:prepend>
            <v-file-input
              v-model="file"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an avatar"
              prepend-icon="mdi-paperclip"
              label="Avatar"
              :hide-input="true"
            ></v-file-input>
          </template>
        </v-text-field>
      </v-col>
      <v-col md="1" v-if="sendingMessage">
        <v-progress-circular
          class="ml-2"
          indeterminate
          color="grey lighten-3"
        ></v-progress-circular>
      </v-col>
      <v-col v-if="file">
        <v-chip close small label color="primary" @click:close="file = null">
          {{ file.name }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <!--lead-comment /-->
        <v-list>
          <v-list-item-group>
            <template v-for="(c, index) in sortedComments">
              <v-list-item
                :key="c._id"
                :class="c.type == 1 ? '' : ''"
                class="px-2"
                v-if="
                  (c.type && c.type == 1 && role_comments === 'admin') ||
                    role_comments === 'marketingv2'
                "
              >
                <template>
                  <div class="my-item-comment">
                    <div class="comment-figure">
                      <div v-if="index === 0" class="arrow up"></div>
                      <div
                        :class="
                          index === 0
                            ? 'figure-divider-final'
                            : 'figure-divider'
                        "
                      ></div>
                      <div class="figure-dot"></div>
                      <div class="figure-divider"></div>
                      <div
                        v-if="index + 1 === sortedComments.length"
                        class="inicio-comments"
                      ></div>
                    </div>
                    <div class="item-comment-content">
                      <div class="comment-content-title">
                        <p class="content-title-primary">C28 Bot</p>
                        <p class="content-title-clock">
                          @{{ c.created_at | dateFormat(true) }}
                        </p>
                      </div>
                      <div class="comment-content-text">
                        <p>{{ c.text }}</p>
                      </div>
                    </div>
                  </div>
                </template>
              </v-list-item>
              <v-list-item
                :key="c._id"
                :class="c.type == 1 ? '' : ''"
                class="px-2"
                v-if="c.type === 0 || !c.type"
              >
                <template>
                  <div class="comment-figure-img">
                    <div v-if="index === 0" class="arrow up"></div>
                    <div
                      :class="
                        index === 0 ? 'figure-divider-final' : 'figure-divider'
                      "
                    ></div>
                    <v-img
                      class="figure-img"
                      min-height="40px"
                      max-height="40px"
                      :src="srcImgMethod(c)"
                    ></v-img>
                    <div class="figure-divider"></div>
                    <div
                      v-if="index + 1 === sortedComments.length"
                      class="inicio-comments"
                    ></div>
                  </div>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ getSafeNestedField(() => getUserComment(c).name, "") }}
                    </v-list-item-title>

                    <v-list-item-subtitle
                      v-if="c.image_src"
                      class="comment-text"
                    >
                      <v-img
                        class="ma-2"
                        :src="c.image_src"
                        height="200px"
                        contain
                        @click="setDialog(!showDialog, c.image_src)"
                      ></v-img>

                      {{ c.text }}

                      <v-dialog v-model="showDialog" width="70%">
                        <v-card>
                          <v-img
                            height="80vh"
                            class="ma-2"
                            :src="selectedImg"
                            aspect-ratio="1.6"
                            contain
                          ></v-img>
                        </v-card>
                      </v-dialog>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-else-if="c.audio_src"
                      class="comment-text"
                    >
                      <vuetify-audio
                        :file="c.audio_src"
                        color="blue-grey darken-4"
                        flat
                      ></vuetify-audio>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else class="comment-text">
                      {{ c.text }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action class="my-0">
                    <v-spacer />
                    <v-list-item-action-text>
                      <small>
                        {{ c.created_at | dateFormat(true) }}
                      </small>
                    </v-list-item-action-text>
                    <!--v-icon
                      color="yellow"
                    >
                      mdi-star
                    </v-icon-->
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import { UserInfo } from "@/api/user-api.js";
import moment from "moment";
moment.locale("es");

export default {
  props: {
    lead: {
      type: Object,
      default: null
    },
    leadId: {
      type: String,
      default: ""
    },
    leadPhase: {
      type: String,
      default: ""
    },
    leadTrackingPhase: {
      type: String,
      default: ""
    },
    leadOperationPhase: {
      type: String,
      default: ""
    },
    comments: {
      type: Array,
      default() {
        return [];
      }
    },
    adminsComments: {
      type: Array,
      default() {
        return [];
      }
    },
    brokersComments: {
      type: Array,
      default() {
        return [];
      }
    },
    developersComments: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    VuetifyAudio: () => import("vuetify-audio")
  },
  data() {
    return {
      showDialog: false,
      selectedImg: "",
      sendingMessage: false,
      newComment: "",
      //Para saber el rol del usuario y saber si puede ver todos los comntarios
      role_comments: localStorage.getItem("user_role") || "",
      file: null
    };
  },
  filters: {
    dateFormat(date, isFullDate = false) {
      if (!date) return "";
      let format = "DD-MMM-YYYY";
      if (isFullDate) format = "DD MMM h:mm a";
      let dateParsed = moment(String(date)).format(format);
      return dateParsed;
    },
    friendlyDifferenceTime(timeToParse, allowFutureTime) {
      const format = "DD/MM/YYYY HH:mm:ss";
      let now = moment(new Date(), format);
      let time = moment(new Date(timeToParse), format);
      // retornar en caso de  trasnformar a fecha el tiempo que se mandó como parámetro
      // y se obtuvo un nan
      if (isNaN(time)) return "";

      let friendlyDifference = "";

      let diffMonths = now.diff(time, "months");
      let diffDays = now.diff(time, "days");
      let diffHours = now.diff(time, "hours");
      let diffMinutes = now.diff(time, "minutes");

      if (allowFutureTime) diffMonths = Math.abs(diffMonths);
      if (allowFutureTime) diffDays = Math.abs(diffDays);
      if (allowFutureTime) diffHours = Math.abs(diffHours);
      if (allowFutureTime) diffMinutes = Math.abs(diffMinutes);

      // si para este momento hay un error calculando la diferencia
      if (isNaN(diffMinutes)) return "";

      // en meses
      if (diffMonths > 0) {
        friendlyDifference = diffMonths + " meses";
        if (diffMonths === 1) friendlyDifference = "1 mes";
      } else {
        // en días
        if (diffDays > 0) {
          friendlyDifference = diffDays + " días";
          if (diffDays === 1) friendlyDifference = "1 día";
        } else {
          // en horas
          if (diffHours > 0) {
            friendlyDifference = diffHours + " horas";
            if (diffHours === 1) friendlyDifference = "1 hora";
          } else {
            // en minutos
            if (diffMinutes > 0) {
              friendlyDifference = diffMinutes + " min";
            } else {
              friendlyDifference = "ahora";
            }
          }
        }
      }
      return friendlyDifference;
    }
  },
  computed: {
    sortedComments() {
      return this.comments.slice().reverse();
    }
  },
  methods: {
    ...mapActions({
      fetchAddComment: "leads/fetchAddComment",
      fetchActualLead: "leads/fetchActualLead",
      fetchNotificationComment: "leads/fetchNotificationComment",
      fetchUpdatelLead: "leads/fetchUpdatelLead"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    setDialog(value, img) {
      this.showDialog = value;
      this.selectedImg = value ? img : "";
    },
    getUserComment(commentObject) {
      let userComment = {};
      if (commentObject.contact_broker_id) {
        userComment = this.brokersComments.find(i => {
          return commentObject.contact_broker_id === i._id;
        });
      }
      if (commentObject.contact_admin_id) {
        userComment = this.adminsComments.find(i => {
          return commentObject.contact_admin_id === i._id;
        });
      }
      if (commentObject.contact_developer_id) {
        userComment = this.developersComments.find(i => {
          return commentObject.contact_developer_id === i._id;
        });
      }
      return userComment;
    },
    createDummyImgFormat(nameBroker = "C28") {
      let returnDummyImg =
        "https://dummyimage.com/600x400/2c344b/fff&text=" +
        nameBroker.substring(0, 2);
      return returnDummyImg;
    },
    handleAddComment() {
      this.sendAddLeadComment(this.leadId, this.newComment);
    },
    // leadId, comment
    async sendAddLeadComment(leadId, commentText) {
      let self = this;
      this.sendingMessage = true;
      // self.setLoading(true);
      let userRole = localStorage.getItem("user_role");
      let userContactId = localStorage.getItem("contact_id"); // contact id of user
      let imageUrl = ''
      if(this.file){
        imageUrl = await this.sendAddFileLead();
      }
      // // let self = this
      let commentData = {
        leadId: leadId,
        commentText: commentText,
        comment_img_src: this.file ? imageUrl : undefined,
        userRole: userRole,
        contactId: userContactId
      };

      this.fetchAddComment(commentData)
        .then(() => {
          // obtener solo comentarios actualizados
          // o actualizar lead item VUEX
          // pero el modal se cierra y abre
          self.setActualLead(leadId);

          this.sendNotificationComment(self.leadId, commentText);

          // self.newComment = ""
          // if (response.Lead && response.Lead.comments)
          //   self.leadComments = response.Lead.comments
          // // enviar notificación al broker asignado
          // if (self.leadBroker) {
          //   let userName = localStorage.getItem('user_name')
          //   let message = userName + ' ha comentado en tu prospecto ' + self.leadContactName
          //   this.sendNotifyBrokerAssigned(self.leadId, self.leadBroker, message)
          // }
        })
        .catch(err => {
          throw err;
        })
        .finally(() => self.clearNewComment());
    },
    allStorage() {
      var values = [],
        keys = Object.keys(localStorage),
        i = keys.length;

      while (i--) {
        values.push(localStorage.getItem(keys[i]));
      }

      return values;
    },
    async sendNotificationComment(leadId, notificationText) {
      const userToken = localStorage.getItem("token");
      const payload = JSON.parse(atob(userToken.split(".")[1]));
      let zone = this.lead.zones ? this.lead.zones : "";
      let name = this.lead.contact_lead_name;
      let userName = "";
      if (
        localStorage.getItem("user_name") === undefined ||
        localStorage.getItem("user_name") === null
      ) {
        let user = await UserInfo();
        localStorage.setItem("user_name", user.User.name);
        userName = user.User.name;
      } else {
        userName = localStorage.getItem("user_name");
      }
      let how =
        this.lead.contact && this.lead.contact.source
          ? this.lead.contact.source.name
          : "Indefinido";
      let notificationData = {
        role: payload.role,
        leadId: leadId,
        contactId: payload.contact_id,
        message: `${userName}: ${notificationText}`,
        headings: this.headingsLayout(name, zone, how),
        largeIcon: localStorage.getItem("user_image"),
        zones: this.lead.zones ? [this.lead.zones] : []
      };
      this.fetchNotificationComment(notificationData);
      // .then((response) => {
      // })
    },
    clearNewComment() {
      this.newComment = "";
      this.sendingMessage = false;
      this.file = null;
    },
    // volver a obtener los datos del lead actual
    // se obtendrían los comentarios actualizados
    setActualLead(leadId) {
      // let self = this;
      // self.setLoading(true);
      let userContactId = localStorage.getItem("contact_id");
      if (
        this.leadTrackingPhase === "assigned" &&
        this.lead.contact_broker_id === userContactId
      ) {
        this.sendUpdateLead();
        this.fetchActualLead(leadId);
      } else {
        this.fetchActualLead(leadId);
      }
    },
    sendUpdateLead() {
      let self = this;
      new Promise(resolve => {
        self
          .fetchUpdatelLead({
            leadId: this.leadId,
            contactLeadId: this.lead.contact_lead_id,
            lead: {
              phase: "active",
              tracking_phase: "tracking",
              operation_phase: "",
              discard_observations: ""
            }
          })
          .catch(() => {
            resolve(); // ¡Todo salió bien!
            self.$snotify.error(
              "Error!!!",
              `Ocurrio un problema actualizar el lead.`,
              {
                closeOnClick: true,
                timeout: 4000
              }
            );
          })
          .finally(async () => {
            self.setLoading(false);
            setTimeout(() => {
              self.innerLoading = false;
              resolve(); // ¡Todo salió bien!
            }, 1000);
            await this.fetchActualLead(this.lead._id);
            // Se hace este parseo para que cuando mute el lead, no cambie el secundario
            let data = JSON.parse(JSON.stringify(this.lead));
            this.$store.commit("leads/setSecondaryLead", data);
          });
      });
    },
    // pasar a una librería
    getSafeNestedField(fn, defaultVal) {
      try {
        return fn();
      } catch (e) {
        return defaultVal;
      }
    },
    headingsLayout(name, zone, how) {
      let heading = "";
      if (name) {
        heading += `${name} `;
      }
      if (zone) {
        heading += `| ${zone} `;
      }
      if (how) {
        heading += `| ${how}`;
      }
      return heading;
    },
    srcImgMethod(c) {
      const imgComment = this.getUserComment(c);
      if (
        imgComment &&
        imgComment.media &&
        imgComment.media.featured_image &&
        imgComment.media.featured_image.src &&
        imgComment.media.featured_image.src !== ""
      ) {
        return imgComment.media.featured_image.src;
      } else if (imgComment && imgComment.name && imgComment.name !== "") {
        const imgAuto = this.createDummyImgFormat(imgComment.name);
        return imgAuto;
      } else {
        return this.createDummyImgFormat();
      }
    },
    async sendAddFileLead() {
      this.isLoading = true;
      const url =
        "https://api.cloudinary.com/v1_1/capital28-investments/upload";
      try {
        // Preparando imagen
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("upload_preset", "comments_image_capital_28");
        // subir imagen a cloudinary
        const data = await fetch(url, { method: "POST", body: formData });
        const json = await data.json();
        // Actuaizar url en la DB
        return json.secure_url
      } catch (e) {
        this.file = null;
        this.$snotify.error(`Ocurrio un erro al subir la imagen.`, `Error!`, {
          closeOnClick: true,
          timeout: 4000
        });
      }
    }
  }
};
</script>
<style scope>

.v-list-item  {
  align-items: normal !important;
}

.v-input__prepend-outer {
  margin-top: 2px !important;
  margin-right: 4px !important;
}

.comment-text {
  padding: 10px;
  border-radius: 5px 5px;
  background-color: #6b717e17;
  white-space: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: justify;
}
.v-input__append-outer i {
  color: #262d3c !important;
}

.my-item-comment {
  width: 100%;
  height: 100%;
  min-height: 82px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 12px 0px 13px;
}

.my-item-comment .comment-figure {
  max-width: max-content;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-item-comment .comment-figure .figure-dot {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background: #60a6a4;
}
.my-item-comment .comment-figure .figure-divider {
  width: 2px;
  height: 36px;
  background: rgba(0, 0, 0, 0.1);
}

.comment-figure-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.comment-figure-img .figure-img {
  width: 40px;
  border-radius: 100%;
}
.comment-figure-img .figure-divider {
  width: 2px;
  min-height: 20px;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.figure-divider-final {
  width: 2px;
  height: 40px;
  min-height: 44px;
  background: rgba(0, 0, 0, 0.1);
}
.arrow {
  border: solid rgba(0, 0, 0, 0.2);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 6px;
  margin: -13px;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.inicio-comments {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 30px;
  min-width: 30px;
  background: rgb(207, 207, 207);
  height: 1px;
}

.my-item-comment .item-comment-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
}

.my-item-comment .item-comment-content .comment-content-title p {
  margin: 0;
}

.my-item-comment .item-comment-content .comment-content-title {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  font-size: 12px;
}
@media (max-width: 668px) {
  .my-item-comment .item-comment-content .comment-content-title {
    flex-direction: column;
    gap: 2px;
  }
}

.my-item-comment
  .item-comment-content
  .comment-content-title
  .content-title-primary {
  font-weight: 400;
}

.my-item-comment
  .item-comment-content
  .comment-content-title
  .content-title-clock {
  font-weight: 300;
}

.my-item-comment .item-comment-content .comment-content-text {
  white-space: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: justify;
}
</style>
