import request from "../helpers/request";
import { requestV2 } from "../helpers/request";
// obtiene la lista de leads
export function AddLeadComment(leadId, commentText, comment_img_src, userRole, contactId) {
  try {
    let data = {
      lead_id: leadId,
      comment: commentText,
      comment_img_src: comment_img_src,
      role: userRole,
      contact_id: contactId
    };

    return new Promise((resolve, reject) => {
      request("/lead/addComment", data)
        .then(response => {
          let lead = response.data.Lead;
          resolve({
            Lead: lead
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function AddLeadFileComment(body) {
  
  try {
    return await requestV2({
      endpoint: "/lead/addComment",
      bodyRequest: body
    });
  } catch (error) {
    throw error;
  }
  
}
