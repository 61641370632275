<template>
  <div v-if="selectedLeads.length > 0">
    <div class="pa-2" style="border: 1px solid gray; border-radius: 0.5rem;">
      <label>
        # Leads seleccionados
        <span v-if="exportAll" class="mx-2 cyan--text">
          (
          {{
            filterLeadsSearchedSt
              ? leadsSearchedSt.length
              : leadsTableFooterSt.total
          }}
          )
        </span>
        <span v-else class="mx-2 blue--text subtitle-2">
          {{ selectedLeads.length }}
        </span>
      </label>
      <div class="my-2" style="display: flex; gap: 0.5rem;">
        <div>
          <v-checkbox
            class="my-0 pa-0"
            hide-details
            :label="
              `Todos (${
                filterLeadsSearchedSt
                  ? leadsSearchedSt.length
                  : leadsTableFooterSt.total
              })`
            "
            v-model="exportAll"
          />
        </div>
        <v-select
          class="select-export-fields"
          v-model="exportFields"
          :items="fields"
          :menu-props="{ maxHeight: '370' }"
          label="Campos a exportar"
          item-text="text"
          item-value="text"
          multiple
          small-chips
          append-outer-icon="mdi-clipboard-text-multiple-outline"
          hide-details
          :disabled="selectedLeads.length === 0"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="exportFields.length > 0 ? 'indigo darken-4' : ''"
                  >{{ icon }}</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Todos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip small v-if="index === 0">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ exportFields.length - 1 }} otros)</span
            >
          </template>
          <v-icon
            slot="append-outer"
            color="secondary"
            @click="exportClipboard"
            :disabled="exportFields.length === 0"
          >
            mdi-clipboard-text-multiple-outline
          </v-icon>
        </v-select>
      </div>
    </div>

    <v-dialog v-model="dialogLoading" max-width="400">
      <v-card>
        <v-card-title class="body-1">
          Obteniendo todos los Leads.
        </v-card-title>
        <v-card-text
          >Se aplican los filtros seleccionados al momento de obtener todos los
          Leads.
        </v-card-text>
        <v-card-text class="text-center">
          <v-progress-circular :value="20" indeterminate></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSuccess" max-width="400">
      <v-card>
        <v-card-title class="body-1 green--text">
          Los Leads se han copiado.
        </v-card-title>
        <v-card-text
          >Puedes pegar los Leads que se copiaron al portapapeles en un archivo
          Excel o .xls presionando Ctrl + V o Command + V
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogSuccess = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("es-mx");
import { mapState } from "vuex";
import { exportLeads } from "../../api/lead-api";

export default {
  data() {
    return {
      dataExport: null,
      dialogLoading: false,
      dialogSuccess: false,
      exportAll: false,
      role: localStorage.getItem("user_role"),
      exportFields: [],
      fields: [
        {
          text: "Fecha de registro",
          value: "created_at",
          format: "datetime"
        },
        {
          text: "Hora de registro",
          value: "created_at",
          format: "hours"
        },
        {
          text: "Nombre",
          value: "contact_lead_name",
          format: "text"
        },
        {
          text: "Teléfono",
          value: "contact_lead.phone",
          format: "phone"
        },
        {
          text: "Correo",
          value: "contact_lead.email",
          format: "email"
        },
        {
          text: "Llegó por",
          value: "zones",
          format: "text"
        },
        {
          text: "Presupuesto",
          value: "budget",
          format: "price"
        },
        {
          text: "Moneda",
          value: "budget",
          format: "currency"
        },
        {
          text: "¿Cómo nos encontró?",
          value: "contact.source:name",
          format: "text"
        },
        {
          text: "¿Cómo nos contactó?",
          value: "contact.medium:name",
          format: "text"
        },
        //Nuevas columnas agregadas...
        {
          text: "Campaña",
          value: "miscellaneous.marketing:campaign",
          format: "text"
        },
        {
          text: "Segmentación",
          value: "miscellaneous.marketing:adSet",
          format: "text"
        },
        /*  {
          text: "Anuncio",
          value: "miscellaneous.marketing:ad",
          format: "text"
        }, */
        //Mas columnas agregadas en Septiembre 2022
        {
          text: "Formulario",
          value: "miscellaneous.marketing.form",
          format: "text"
        },
        {
          text: "Costo",
          value: "miscellaneous.marketing.cost",
          format: "text"
        },
        {
          text: "Ventas",
          value: "miscellaneous.marketing.sales ",
          format: "text"
        },
        {
          text: "Ingresos",
          value: "miscellaneous.marketing.revenue",
          format: "text"
        },
        {
          text: "Palabra Clave",
          value: "miscellaneous.marketing.utm_term",
          format: "text"
        },
        {
          text: "Anuncio",
          value: "miscellaneous.marketing.ad",
          format: "text"
        },

        //Fin de las columnas agregadas...
        {
          text: "Ubicación",
          value: "contact_lead.location",
          format: "text"
        },
        {
          text: "Probable",
          value: "profile",
          format: "text"
        },
        {
          text: "Observaciones",
          value: "observations_detail",
          format: "text"
        },
        {
          text: "% Perfilación",
          value: "profile_percentage",
          format: "percentage"
        },
        {
          text: "Broker asignado",
          value: "internal_broker.name",
          format: "text"
        },
        {
          text: "Status",
          value: "phase",
          format: "phase"
        },
        {
          text: "Comentarios",
          value: "totalComment",
          format: "text"
        },
        {
          text: "Motivo de descarte",
          value: "discard_observations",
          format: "text"
        },
        {
          text: "Idioma",
          value: "contact.language",
          format: "text"
        },
        {
          text: "Perfil App",
          value: "completeLeadSurvey",
          format: "text"
        }
      ]
    };
  },
  watch: {
    selectedLeads() {
      this.exportAll = false;
      this.dataExport = null;
    },
    exportAll() {
      this.dataExport = null;
    },
    exportFields: {
      handler() {
        this.dataExport = null;
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      selectedLeads: state => state.leads.selectedItems,
      filterLeadsSearchedSt: state => state.leads.filterLeadsSearched,
      leadsSearchedSt: state => state.leads.leadsSearched,
      filtersSelectedSt: state => state.leads.filtersSelected,
      leadsTableFooterSt: state => state.leads.leadsTableFooter
    }),
    isAllItemsSelected() {
      return this.exportFields.length === this.fields.length;
    },
    isSomeItemsSelected() {
      return this.exportFields.length > 0 && !this.isAllItemsSelected;
    },
    icon() {
      if (this.isAllItemsSelected) return "mdi-close-box";
      if (this.isSomeItemsSelected) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    async exportClipboard() {
      if (this.dataExport !== null) {
        this.updateClipboard(this.dataExport);
        return;
      }
      const body = {
        fields: this.exportFields,
        includePerfilApp: this.exportFields.includes("Perfil App")
      };
      if (this.exportAll) {
        body.filter = this.filtersSelectedSt;
      } else {
        body.leadIds = this.selectedLeads.map(i => i._id);
      }
      this.dialogLoading = true;
      this.dataExport = await exportLeads({...body, formatDate: true,});
      this.dialogLoading = false;
      this.updateClipboard(this.dataExport);
    },
    updateClipboard(newClip) {
      const self = this;
      if (!newClip) {
        return "";
      } else {
        navigator.clipboard.writeText(newClip).then(
          function() {
            /* clipboard successfully set */
            self.dialogSuccess = true;
            self.$snotify.info("Copiado al portapapeles", {
              timeout: 1000,
              showProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false
            });
          },
          function() {
            /* clipboard write failed */
            self.$snotify.error(
              "Ah ocurrido un problema, inténtelo más tarde",
              {
                timeout: 1500,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false
              }
            );
          }
        );
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.isAllItemsSelected) {
          this.exportFields = [];
        } else {
          this.exportFields = this.fields.map(i => i.text);
        }
      });
    }
  },
  created() {
    if (this.role === "developer") {
      const removeItems = [2, 3, 5, 11, 13];
      this.exportFields = this.fields.filter(
        (itm, idx) => !removeItems.includes(idx)
      );
    }
  }
};
</script>
<style scoped>
.select-export-fields {
  width: 300px;
  max-width: 300px;
}
.select-export-fields.disabled i {
  color: gray !important;
  background-color: gray !important;
}
#no-background-hover::before {
  cursor: default !important;
  background-color: transparent !important;
}
</style>
