<template>
  <div class="cell-style">
    <div class=" lighten-5">
      <slot />
    </div>
    <v-divider></v-divider>
    <div class=" lighten-5" v-if="showManual">
      <slot name="manual" />
    </div>
  </div>
</template>

<script>
export default {
  name: "wrapperItemComertialReport",
  computed: {
    showManual() {
      return !!this.$slots.manual;
    }
  }
};
</script>

<style scoped>
.cell-style {
  display: flex;
  flex-direction: column; /* Apila los elementos verticalmente */
  height: 100%; /* Ocupa toda la altura de la celda */
  width: 100%; /* Ocupa toda la anchura de la celda */
}
.cell-style div {
  flex: 1; /* Hace que cada <p> ocupe el mismo espacio disponible */
  display: flex;
  align-items: center; /* Centra el contenido verticalmente dentro del <p> */
  justify-content: center; /* Centra el texto horizontalmente */
}
</style>
