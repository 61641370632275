<template>
  <div>
    <span v-if="showBudgetPhase" class="budget-phase exchange">{{
      budget
    }}</span>

    <div
      v-else
      @click="!func || isTotalZero ? () => {} : customAction()"
      :class="!func || isTotalZero ? '' : 'total-info'"
    >
      <span v-if="isTotalZero" class="grey--text text--darken-1 italic-style"
        >-</span
      >
      <strong v-else>{{ total }}</strong>
      <span v-if="!isTotalZero" class="percentStyle grey--text text--darken-1">
        ({{ percentOfTotal }}%)
      </span>
    </div>
  </div>
</template>

<script>
// separe in a component
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    phase: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    showBudgetPhase: {
      type: Boolean,
      required: false,
      default: false
    },
    budget: {
      type: String,
      required: false,
      default: "-"
    },
    func: {
      type: Function,
      required: false
    }
  },
  computed: {
    isTotalZero() {
      return this.total === 0;
    },
    percentOfTotal() {
      return this.getPercent(this.total, this.item.totalByContact);
    }
  },
  methods: {
    getPercent(value, total) {
      return ((value * 100) / total).toFixed(2);
    },
    customAction() {
      this.func();
    }
  }
};
</script>

<style>
.italic-style {
  font-style: italic;
}

.budget-phase {
  color: #1b5e20;
  font-style: italic;
}

.total-info {
  cursor: pointer;
}
.percentStyle {
  font-style: italic;
  font-size: 13px;
  font-size: smaller;
}
.exchange {
  font-size: 13px !important;
}
</style>
