<template>
  <div
    class="pa-2"
    style="border: 1px solid gray; border-radius: 0.5rem;"
    v-if="selectedLeads.length > 0"
  >
    <p class="ma-0">Asignar masivamente a un asesor</p>
    <div style="display: flex; align-items: center;">
      <select-contact-broker
        :lead="null"
        :page="'leadReassing'"
        @getOption="getContactBrokerOption"
      />
      <v-btn
        :loading="loading"
        :disabled="invalid"
        class="ma-2"
        color="blue lighten-2"
        dark
        @click="reassignedOpen"
      >
        <v-icon dark>
          mdi-cloud-upload
        </v-icon>
      </v-btn>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <!-- <v-card-title class="headline">
          {{ contactBrokerName }}
        </v-card-title> -->
          <v-card-text class="py-5 title  black--text">
            <template
              v-if="
                arrayLeadsIdReassigned.length !== 0 ||
                  arrayLeadsIdAssigned.length !== 0
              "
            >
              ¿Estás seguro que quieres asignar/reasignar
              <span>
                <span class="red--text">
                  {{
                    arrayLeadsIdReassigned.length + arrayLeadsIdAssigned.length
                  }}
                </span>
                nuevos Leads a
                <span class="blue--text">
                  {{ contactBrokerName }}
                </span>
                ?
              </span>
            </template>
            <br />
          </v-card-text>
          <v-card-text class="text--primary">
            <!-- <template v-if="arrayLeadsIdReassigned.length !== 0">
            ¿Estas seguro que quieres reasignar
            <span v-if="arrayLeadsIdReassigned.length <= 1">
              un nuevo Lead?
            </span>
            <span v-else>
              {{ arrayLeadsIdReassigned.length }} nuevos Leads?
            </span>
          </template>
          <br /> -->
            <template v-if="arrayLeadsIdAssigned.length !== 0">
              <span v-if="arrayLeadsIdAssigned.length.length <= 1">
                Se le asignará un nuevo Lead
              </span>
              <span v-else>
                Se le asignarán {{ arrayLeadsIdAssigned.length }} nuevos Leads
              </span>
            </template>
          </v-card-text>
          <v-card-text class="text--primary">
            <!-- <template v-if="arrayLeadsIdReassigned.length !== 0">
            ¿Estas seguro que quieres reasignar
            <span v-if="arrayLeadsIdReassigned.length <= 1">
              un nuevo Lead?
            </span>
            <span v-else>
              {{ arrayLeadsIdReassigned.length }} nuevos Leads?
            </span>
          </template>
          <br /> -->
            <template v-if="arrayLeadsIdReassigned.length !== 0">
              <span v-if="arrayLeadsIdReassigned.length.length <= 1">
                Se le reasignará un nuevo) Lead
              </span>
              <span v-else>
                Se le reasignarán {{ arrayLeadsIdReassigned.length }}
                Leads de otros asesores.
              </span>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">
              Cancel
            </v-btn>
            <v-btn color="primary" dark @click="saveReassignedLeads">
              <div class="px-10">
                Asignar
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogLoading" max-width="400">
        <v-card>
          <v-card-title class="body-1">
            Obteniendo todos los Leads.
          </v-card-title>
          <v-card-text
            >Se aplican los filtros seleccionados al momento de obtener todos
            los Leads.
          </v-card-text>
          <v-card-text class="text-center">
            <v-progress-circular
              :value="20"
              indeterminate
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  components: {
    SelectContactBroker: () =>
      import("@/components/contact-broker/SelectContactBroker.vue")
  },
  data: () => ({
    dialogLoading: false,
    selectedAllLeads: false,
    contactBrokerId: "",
    contactBrokerName: "",
    loading: false,
    loader: null,
    dialogDelete: false,
    countLeads: 0,
    invalid: true,
    contactRole: "",
    arrayLeadsIdReassigned: [],
    arrayLeadsIdAssigned: [],
    noUnassigned: true
  }),
  watch: {},
  computed: {
    ...mapState({
      selectedLeads: state => state.leads.selectedItems,
      filtersSelectedSt: state => state.leads.filtersSelected,
      leadsTableFooterSt: state => state.leads.leadsTableFooter
    })
  },
  methods: {
    ...mapActions({
      reassignedLeadstoContact: "contacts/reassignedLeadstoContact",
      notifyReassignedLeads: "contacts/notifyReassignedLeads",
      fetchLeadsleadsToExportSt: "leads/fetchLeadsToExport"
    }),
    ...mapMutations({
      selectedLeadstMutation: "leads/setSelectedItems"
    }),
    selectedAll(value) {
      this.selectedAllLeads = value;
    },
    clearSelected() {
      this.$emit("close", true);
    },
    reassignedOpen() {
      if (this.selectedAllLeads) {
        this.dialogLoading = true;
        this.fetchLeadsleadsToExportSt({
          filter: this.filtersSelectedSt
        }).then(values => {
          this.selectedLeadstMutation(values);
          this.selectedLeads.forEach(lead => {
            if (lead.contact_broker_id) {
              this.arrayLeadsIdReassigned.push(lead["_id"]);
            } else {
              this.arrayLeadsIdAssigned.push(lead["_id"]);
            }
          });
          if (this.contactBrokerId && this.noUnassigned === true) {
            this.dialogDelete = true;
            this.countLeads = this.selectedLeads.length;
          }
          this.dialogLoading = false;
        });
      } else {
        this.selectedLeads.forEach(lead => {
          if (lead.contact_broker_id) {
            this.arrayLeadsIdReassigned.push(lead["_id"]);
          } else {
            this.arrayLeadsIdAssigned.push(lead["_id"]);
            // this.$snotify.error(`Lead ${
            //     !lead.contact_lead_name ? "Sin Nombre" : lead.contact_lead_name
            //   } no esta asignado desmarcar casilla`
            // );
            // this.noUnassigned= false;
          }
        });
        if (this.contactBrokerId && this.noUnassigned === true) {
          this.dialogDelete = true;
          this.countLeads = this.selectedLeads.length;
        }
      }
    },
    getContactBrokerOption(option) {
      if (!option) {
        this.contactBrokerId = "";
        this.contactBrokerName = "";
        this.contactRole = "";
      } else {
        this.contactBrokerId = option._id;
        this.contactBrokerName = option.name;
        this.contactRole = option.role;
        this.invalid = false;
      }
    },
    saveReassignedLeads() {
      if (this.arrayLeadsIdReassigned.length !== 0) {
        const datadReassigned = {
          leads_id_array: this.arrayLeadsIdReassigned,
          contact_id: this.contactBrokerId
        };
        this.sendAssignedLeadsToContact(
          datadReassigned,
          "Reasignación de Leads"
        );
      }
      if (this.arrayLeadsIdAssigned.length !== 0) {
        const dataAssigned = {
          leads_id_array: this.arrayLeadsIdAssigned,
          contact_id: this.contactBrokerId,
          assigned: true
        };
        this.sendAssignedLeadsToContact(dataAssigned, "Asignacion de Leads");
      }
    },
    sendAssignedLeadsToContact(dataRequest, message) {
      let self = this;
      this.$snotify.async(
        "Actualizando Leads",
        "Actualizando ...",
        () =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              this.reassignedLeadstoContact(dataRequest)
                .then(res => {
                  resolve({
                    title: message,
                    body: `${message} exitosa`,
                    config: {
                      closeOnClick: true,
                      titleMaxLength: 30,
                      timeout: 4000
                    }
                  });
                  this.$store.dispatch("contacts/notifyReassignedLeads", {
                    contactBrokerId: self.contactBrokerId,
                    role: self.contactRole,
                    nLeads: res.ContactLeads.leads
                  });
                  self.dialogDelete = false;
                  self.arrayLeadsIdAssigned = [];
                })
                .catch(err => {
                  reject({
                    title: "Error!!!",
                    body: `Ocurrio un problema en el proceso. ${err}`,
                    config: {
                      closeOnClick: true,
                      timeout: 4000
                    }
                  });
                  self.dialogDelete = false;
                })
                .finally(() => {
                  setTimeout(() => {
                    // self.setLoading(false);
                    self.selectedLeads = [];
                    self.arrayLeadsIdReassigned = [];
                    self.arrayLeadsIdAssigned = [];
                    self.dialogDelete = false;
                    self.clearSelected(true);
                  }, 1000);
                });
            }, 500);
          })
      );
    },
    close() {
      this.dialogDelete = false;
      self.selectedLeads = [];
      this.arrayLeadsIdReassigned = [];
      this.arrayLeadsIdAssigned = [];
    }
  }
};
</script>
<style>
.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #64b5f6 !important;
  border-color: #64b5f6 !important;
}
</style>
